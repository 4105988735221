import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import AuthContext from '../context/AuthContext';
import returnToHandler from '../helpers/returnToHandler';
import changePathName from '../helpers/changePathName';

const onClick = (backPath, router, returnTo) => {
  if (backPath) {
    changePathName(router, backPath);
  } else {
    returnToHandler(returnTo);
  }
};

const BackBtn = ({ backPath }) => {
  const router = useRouter();
  const {
    returnTo
  } = useContext(AuthContext);

  return (
    <button
      type="button"
      className="back-btn"
      onClick={() => onClick(backPath, router, returnTo)}
    >
      back
    </button>
  );
};

BackBtn.propTypes = {
  backPath: PropTypes.string
};

export default BackBtn;
